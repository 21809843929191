import { render, staticRenderFns } from "./siteAdminList.vue?vue&type=template&id=1bdf5dea&scoped=true&"
import script from "./siteAdminList.vue?vue&type=script&lang=babel&"
export * from "./siteAdminList.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bdf5dea",
  null
  
)

export default component.exports