<template>
  <eagle-list
    :list-key="listKey"
  ></eagle-list>
</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './siteAdminListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'site-admin-list',
    meta: {},
  }),
  methods: {
    async beforeIndex() {
      await Promise.all([
        this.loadSiteAdminRoles(),
      ])
    },
    async loadSiteAdminRoles() {
      const result = await this.$api.collection.siteAdminApi.roles()
      this.meta.roles = result.roles
    },
    async indexApi(params) {
      return await this.$api.collection.siteAdminApi.index(params)
    },
    async patchApi(value, row, config) {
      const target = row.id
      const column = config.key
      return await this.$api.collection.siteAdminApi.patch(target, column, value)
    },
    async deleteApi(target) {
      return await this.$api.collection.siteAdminApi.delete(target)
    },
    async batchApi(targets, column, value) {
      return await this.$api.collection.siteAdminApi.batch(targets, column, value)
    },
    async batchDeleteApi(targets) {
      return await this.$api.collection.siteAdminApi.batchDelete(targets)
    },
    getListConfig() {
      return listConfig
    },
    resetPassword(row) {
      this.$apopup.base({
        title: this.$t('action.reset-password'),
        bodySlot: () => import('modules/siteAdmin/views/siteAdminList/resetPasswordPopup.vue'),
        disabledApply: data => {
          if(!data) return true
          if(this.$helper.textEmpty(data.password)) return true
          if(this.$helper.textEmpty(data.password_confirm)) return true
          if(!this.$eagleLodash.isEqual(data.password, data.password_confirm)) return true
          return false
        },
        applyCallback: data => this.resetPasswordRequest(row, data),
      })
    },
    async resetPasswordRequest(row, data) {
      try {
        await this.$api.collection.siteAdminApi.resetPassword(row.id, data)
        this.$snotify.success(
          null,
          this.$t('save.successfully')
        )
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('save.failure.unfinished'),
          this.$t('save.failure'),
        )
        throw error
      }
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
